<template>
    <v-card flat>
        <v-toolbar flat color="grey lighten-5">
            <v-toolbar-title>
                <font-awesome-icon :icon="['fas', 'globe']"></font-awesome-icon>
                <span class="ml-4">{{ domain }}</span>
            </v-toolbar-title>
            <v-spacer></v-spacer>
            <!-- <v-btn icon>
                <font-awesome-icon :icon="['fas', 'globe']"></font-awesome-icon>
            </v-btn> -->
            <!-- <template #extension v-if="accountId">
                <span style="font-size: 0.75em;">
                    <router-link :to="{ name: 'account-view-domain', params: { accountId, domain } }">Overview</router-link>
                    <span class="mx-4"></span>
                    <router-link :to="{ name: 'account-edit-dns', params: { accountId, domain } }">DNS Records</router-link>
                </span>
            </template> -->
            <span style="font-size: 0.75em;">
                <router-link :to="{ name: 'account-view-domain', params: { accountId, domain } }">Overview</router-link>
                <span class="mx-4"></span>
                <router-link :to="{ name: 'account-edit-dns', params: { accountId, domain } }">DNS Records</router-link>
            </span>
        </v-toolbar>
    </v-card>
</template>
<script>
export default {
    props: ['accountId', 'domain'],
};
</script>
